footer {
  @apply w-full;
}
.footer-wrapper {
  @apply flex flex-col items-center justify-between py-6 sm:py-4;
}
.link-button {
  @apply my-1 p-2 hover:rotate-6;
}
.link-button svg {
  @apply scale-125;
}
.copyright-wrapper {
  @apply my-2 flex flex-col items-center whitespace-nowrap sm:flex-row;
}
.separator {
  @apply hidden sm:inline;
}
