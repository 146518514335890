main {
  @apply mx-auto w-full max-w-3xl px-4 pb-12;
}

.post-title {
  @apply text-2xl font-semibold text-skin-accent;
}

.post-metadata {
  @apply my-2 flex items-center space-x-2 text-base italic opacity-80;
}

.all-posts h1 {
  @apply my-4 inline-block text-3xl font-bold sm:my-8 sm:text-5xl;
}

.all-posts section {
  @apply pb-6 pt-12;
}

.posts-list {
  @apply text-2xl tracking-wide;
}

.posts-list a {
  @apply inline-block text-lg font-medium text-skin-accent decoration-dashed underline-offset-4 focus-visible:no-underline focus-visible:underline-offset-0;
}

.posts-list h3 {
  @apply text-lg font-semibold decoration-dashed hover:underline;
}
