.board-members-container {
  @apply flex flex-col gap-y-4;
}

.board-member {
  @apply flex flex-col gap-x-8 sm:flex-row;
}

.board-image-outer-container {
  @apply relative size-48 flex-shrink-0 overflow-hidden rounded-full;
}

.board-image-container {
  @apply relative inline-block bg-white leading-[0] after:absolute after:left-0 after:top-0 after:h-full after:w-full after:opacity-[0.76] after:mix-blend-multiply after:content-[''];
}
.board-image-container::after {
  @apply bg-skin-accent;
}
.board-image-container > img {
  @apply !m-0 opacity-[100%] brightness-[110%] grayscale hue-rotate-0 invert-[0%] saturate-[100%] sepia-[0%];
  mix-blend-mode: none;
}

.board-details {
  @apply flex-grow;
}

.board-details h3 {
  @apply mt-4;
}
