/* If you need to add @import statements, do so up here */

@import "./components/header.css";
@import "./components/footer.css";
@import "./components/home.css";
@import "./components/post.css";
@import "./components/event-details.css";
@import "./components/board.css";

@import "./typography/IBMPlexMono-Bold.css";
@import "./typography/IBMPlexMono-BoldItalic.css";
@import "./typography/IBMPlexMono-ExtraLight.css";
@import "./typography/IBMPlexMono-ExtraLightItalic.css";
@import "./typography/IBMPlexMono-Italic.css";
@import "./typography/IBMPlexMono-Light.css";
@import "./typography/IBMPlexMono-LightItalic.css";
@import "./typography/IBMPlexMono-Medium.css";
@import "./typography/IBMPlexMono-MediumItalic.css";
@import "./typography/IBMPlexMono-Regular.css";
@import "./typography/IBMPlexMono-SemiBold.css";
@import "./typography/IBMPlexMono-SemiBoldItalic.css";
@import "./typography/IBMPlexMono-Text.css";
@import "./typography/IBMPlexMono-TextItalic.css";
@import "./typography/IBMPlexMono-Thin.css";
@import "./typography/IBMPlexMono-ThinItalic.css";

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@view-transition {
  navigation: auto;
}

@media (prefers-reduced-motion) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}

@layer base {
  :root,
  html {
    --color-fill: 11, 25, 54;
    --color-text-base: 234, 237, 243;
    --color-accent: 231, 20, 192;
    --color-card: 52, 63, 96;
    --color-card-muted: 138, 51, 2;
    --color-border: 145, 13, 116;
  }

  html {
    @apply h-full bg-skin-fill;
  }
  body {
    @apply flex min-h-full flex-col bg-skin-fill font-mono text-skin-base selection:bg-skin-accent selection:bg-opacity-70 selection:text-skin-inverted;
  }
  section,
  footer {
    @apply mx-auto max-w-3xl px-4;
  }
  a {
    @apply outline-2 outline-offset-1 outline-skin-fill focus-visible:no-underline focus-visible:outline-dashed;
  }
  svg {
    @apply inline-block h-6 w-6 fill-skin-base group-hover:fill-skin-accent;
  }
  svg.icon-tabler {
    @apply inline-block h-6 w-6 scale-125 fill-transparent stroke-current stroke-2 opacity-90 group-hover:fill-transparent sm:scale-110;
  }
  .prose {
    @apply prose-headings:!mb-3 prose-headings:!text-skin-base prose-h3:italic prose-p:!text-skin-base prose-a:!text-skin-base prose-a:!decoration-dashed prose-a:underline-offset-8 hover:prose-a:text-skin-accent prose-blockquote:!border-l-skin-accent prose-blockquote:border-opacity-50 prose-blockquote:opacity-80 prose-figcaption:!text-skin-base prose-figcaption:opacity-70 prose-strong:!text-skin-base prose-code:rounded prose-code:bg-skin-card prose-code:bg-opacity-75 prose-code:p-1 prose-code:!text-skin-base prose-code:before:!content-[''] prose-code:after:!content-[''] prose-pre:!text-skin-base prose-ol:!text-skin-base prose-ul:overflow-x-clip prose-ul:!text-skin-base prose-li:marker:!text-skin-accent prose-table:text-skin-base prose-th:border prose-th:border-skin-line prose-td:border prose-td:border-skin-line prose-img:mx-auto prose-img:!mt-2 prose-img:border-2 prose-img:border-skin-line prose-hr:!border-skin-line;
  }
  .prose a {
    @apply hover:!text-skin-accent;
  }
  .prose thead th:first-child,
  tbody td:first-child,
  tfoot td:first-child {
    padding-left: 0.5714286em;
  }
  .prose h2#table-of-contents {
    @apply mb-2;
  }
  .prose details {
    @apply inline-block cursor-pointer select-none text-skin-base;
  }
  .prose summary {
    @apply focus-outline;
  }
  .prose h2#table-of-contents + p {
    @apply hidden;
  }

  /* ===== scrollbar ===== */
  html {
    overflow-y: scroll;
  }
}

@layer components {
  .display-none {
    @apply hidden;
  }
  .focus-outline {
    @apply outline-2 outline-offset-1 outline-skin-fill focus-visible:no-underline focus-visible:outline-dashed;
  }
}
