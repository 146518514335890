/* Subset: Cyrillic */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local("IBM Plex Mono Bold Italic"),
    local("IBMPlexMono-BoldItalic"),
    url("../fonts/IBMPlexMono-BoldItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2,
    U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9;
}
/* Subset: Latin1 */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local("IBM Plex Mono Bold Italic"),
    local("IBMPlexMono-BoldItalic"),
    url("../fonts/IBMPlexMono-BoldItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014,
    U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2122, U+2212, U+FB01-FB02;
}
/* Subset: Latin2 */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local("IBM Plex Mono Bold Italic"),
    local("IBMPlexMono-BoldItalic"),
    url("../fonts/IBMPlexMono-BoldItalic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-0101, U+0104-0130, U+0132-0151, U+0154-017F, U+018F, U+0192, U+01A0-01A1, U+01AF-01B0,
    U+01FA-01FF, U+0218-021B, U+0237, U+0259, U+1E80-1E85, U+1E9E, U+20A1, U+20A4, U+20A6, U+20A8-20AA, U+20AD-20AE,
    U+20B1-20B2, U+20B4-20B5, U+20B8-20BA, U+20BD, U+20BF;
}
/* Subset: Latin3 */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local("IBM Plex Mono Bold Italic"),
    local("IBMPlexMono-BoldItalic"),
    url("../fonts/IBMPlexMono-BoldItalic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+01CD-01DC, U+1EA0-1EF9, U+20AB;
}
/* Subset: Pi */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local("IBM Plex Mono Bold Italic"),
    local("IBMPlexMono-BoldItalic"),
    url("../fonts/IBMPlexMono-BoldItalic-Pi.woff2") format("woff2");
  unicode-range: U+03C0, U+0E3F, U+2032-2033, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E,
    U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202,
    U+2206, U+220F, U+2211, U+2215, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2500-259F, U+25CA, U+2713,
    U+274C, U+2B0E-2B11;
}
