#skip-to-content {
  @apply absolute -top-full left-16 z-50 bg-skin-accent px-3 py-2 text-skin-inverted transition-all focus:top-4;
}
.nav-container {
  @apply mx-auto flex max-w-3xl flex-col items-center justify-between sm:flex-row;
}
.top-nav-wrap {
  @apply relative flex w-full items-start justify-between p-4 sm:items-center sm:py-8;
}
.logo {
  @apply absolute text-xl font-semibold sm:static sm:text-2xl;
}
.hamburger-menu {
  @apply self-end p-2 sm:hidden;
}
.hamburger-menu svg {
  @apply h-6 w-6 scale-125 fill-skin-base;
}

nav {
  @apply flex w-full flex-col items-center bg-skin-fill sm:ml-2 sm:flex-row sm:justify-end sm:space-x-4 sm:py-0;
}
nav ul {
  @apply mt-4 grid w-44 grid-cols-2 grid-rows-4 gap-x-2 gap-y-2 sm:ml-0 sm:mt-0 sm:w-auto sm:gap-x-5 sm:gap-y-0;
}
nav ul li {
  @apply col-span-2 flex items-center justify-center;
}
nav ul li a {
  @apply w-full px-4 py-3 text-center font-medium hover:text-skin-accent sm:my-0 sm:px-2 sm:py-1;
}
nav a.active {
  @apply underline decoration-wavy decoration-2 underline-offset-4;
}
nav a.active svg {
  @apply fill-skin-accent;
}
nav button {
  @apply p-1;
}
nav button svg {
  @apply h-6 w-6 fill-skin-base hover:fill-skin-accent;
}
#theme-btn {
  @apply p-3 sm:p-1;
}
#theme-btn svg {
  @apply scale-125 hover:rotate-12 sm:scale-100;
}

.icon-container {
  @apply h-5 w-6 flex-col items-end justify-between;
}
.icon-container div {
  @apply h-0.5 bg-skin-inverted transition-all;
}
#first-line {
  @apply w-full;
}
#second-line {
  @apply w-3/4;
}
#third-line {
  @apply w-1/2;
}
